<script setup lang="ts">
import useUiFormElement from '@mop/ui/composables/useUiFormElement';

defineOptions({
  name: 'UiFormTextArea',
});

const props = defineProps({
  id: {
    type: String,
    default: null,
  },
  modelValue: {
    type: String,
    default: null,
  },
  name: {
    type: String,
    default: null,
  },
  maxInputLength: {
    type: Number,
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },

  rules: {
    type: Array,
    default: null,
  },
  hideErrors: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);
const attrs = useAttrs();

const { class: className, ...attributes } = attrs;
const { validationErrors, register, validate } = useUiFormElement(props);
defineExpose({ validate });

async function handleChange(event: Event) {
  emit('update:modelValue', (event.target as HTMLTextAreaElement).value);
  await nextTick();
  await validate();
}

onBeforeMount(() => {
  register(getCurrentInstance());
});
</script>

<template>
  <div :class="`form-textarea ${className}`">
    <textarea
      :id="id"
      class="form-text-area"
      v-bind="attributes"
      :name="name"
      :value="modelValue"
      :maxlength="maxInputLength"
      :placeholder="placeholder"
      @input="handleChange"
    />
    <div v-if="!hideErrors && validationErrors.length" class="form-error form-error--select">
      {{ $mopI18n.t(validationErrors[0]) }}
    </div>
  </div>
</template>
